import React from 'react'

import './ProductInfoCard.scss'

class ProductInfoCard extends React.Component {
  render() {
    return (
      <div className="product-info-card">
        <div className="card-text">
          <h4 className="card-title">{this.props.siteMeta.name}</h4>
        </div>
        <p className="card-description">{this.props.siteMeta.about}</p>
        <a className="card-button" href="/">
          Find out more →
        </a>
      </div>
    )
  }
}

export default ProductInfoCard
