import React from 'react'
import Share from '../Share'
import './Article.scss'

class Article extends React.Component {
  render() {
    const post = this.props.post
    const content = this.props.content
    const siteMeta = this.props.siteMeta

    return (
      <article
        itemScope
        itemType="https://schema.org/Article"
        className="page-content"
      >
        {post.frontmatter.kind !== 'tool' && (
          <header className="page-header">
            <div className="page-header-text">
              <h1 className="page-header-title" itemProp="headline">
                {post.frontmatter.title}
              </h1>

              {post.frontmatter.type !== 'page' ? (
                <>
                  <span className="page-header-author">{siteMeta.author}</span>
                  <span className="page-header-reading-time">
                    {post.timeToRead} mins
                  </span>
                </>
              ) : null}
            </div>
          </header>
        )}
        <section className="page-body" itemProp="articleBody">
          {content}
        </section>
        <Share title={post.frontmatter.title} pathname={post.fields.slug} />
      </article>
    )
  }
}

export default Article
